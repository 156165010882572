$color-E30613: #e30613;
$color-CD0511: #cd0511;
$color-FEEFF0: #feeff0;
$color-000000: #000000;
$color-282525: #282525;
$color-3F3B3B: #3f3b3b;
$color-525050: #525050;
$color-7D7B7B: #7d7b7b;
$color-A7A6A6: #a7a6a6;
$color-D2D1D1: #d2d1d1;
$color-E7E7E7: #e7e7e7;
$color-F1F1F1: #f1f1f1;
$color-F8F8F8: #f8f8f8;
$color-FBFBFB: #fbfbfb;
$color-FFFFFF: #ffffff;
$color-0C9D58: #0c9d58;
$color-FF3A0F: #ff3a0f;
$color-FFC700: #ffc700;
$color-E3E8EB: #e3e8eb;
$color-FFF6F6: #fff6f6;
$color-FFD978: #ffd978;
$color-FFD4C7: #ffd4c7;
$color-EAF3FA: #eaf3fa;
$color-0761A3: #0761a3;
