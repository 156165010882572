@use 'src/styles/base/colors' as *;

.form-control-checkbox {
  &.is-checked {
    .checkbox {
      &:before {
        background-color: $color-E30613;
      }

      &:after {
        mask: url('../../assets/icons/check/check-black-24x24.svg');
        background-color: $color-FFFFFF;
      }
    }
  }

  &.is-disabled {
    .checkbox {
      cursor: not-allowed;

      &:before {
        background-color: $color-F1F1F1;
      }
    }

    .label {
      color: $color-A7A6A6;
    }
  }

  &.is-checked.is-disabled {
    .checkbox {
      cursor: not-allowed;

      &:before {
        background-color: $color-7D7B7B;
      }
    }
  }

  .checkbox {
    position: relative;
    display: flex;
    gap: 10px;
    cursor: pointer;
    background-color: transparent;

    &:before {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      min-width: 22px;
      height: 22px;
      border-radius: 8px;
      background-color: $color-E7E7E7;
    }

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      min-width: 22px;
      height: 22px;
      border-radius: 8px;
    }
  }

  .input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }

  .errors-list {
    padding-left: 30px;
  }
}
