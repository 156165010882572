@use 'src/styles/base/variables' as *;
@use 'src/styles/base/colors' as *;

app-table-row {
  &.selected {
    app-table-row-cell {
      background-color: $color-FBFBFB;
      border-color: $color-E30613;
    }
  }

  app-table-row-cell {
    border-width: 1px 0;
    border-style: solid;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;

    &.active {
      color: $color-0C9D58;
    }

    &.blocked {
      color: $color-FF3A0F;
    }

    &:first-child {
      border-left-width: 1px;
    }

    &:last-child {
      border-right-width: 1px;
    }
  }
}

.table {
  height: 100%;
}

.table-row-details {
  border-bottom: 1px solid $color-F1F1F1;
}

.workshops-table {
  .table-header,
  .table-content {
    grid-template-columns:
      repeat(8, minmax($table-column-min-width, 1fr))
      1fr
      auto
      max(60px);
  }
}

.users-table {
  .table-header,
  .table-content {
    grid-template-columns:
      repeat(5, minmax($table-column-min-width, 1fr))
      1fr
      auto
      max(60px);
  }
}

.news-table {
  .table-header,
  .table-content {
    grid-template-columns:
      minmax($table-column-min-width, 140px)
      repeat(2, minmax($table-column-min-width, 2fr))
      minmax($table-column-min-width, 1fr)
      repeat(2, minmax($table-column-min-width, 140px))
      minmax($table-column-min-width, 140px)
      auto;
  }
}

.changelog-table {
  .table-header,
  .table-content {
    grid-template-columns:
      minmax($table-column-min-width, 140px)
      repeat(4, minmax($table-column-min-width, 1fr))
      auto;
  }
}

.roles-table {
  .table-header,
  .table-content {
    grid-template-columns:
      minmax($table-column-min-width, $table-column-max-width)
      minmax($table-column-min-width, 1fr)
      repeat(2, minmax($table-column-min-width, $table-column-max-width))
      auto;
  }
}

.material-report-list-item-details-table {
  .table {
    border: none !important;
    background-color: $color-F8F8F8 !important;
  }

  .table-content {
    overflow: hidden;

    app-table-row {
      app-table-row-cell {
        background-color: $color-F8F8F8;
        color: $color-282525;
        border-bottom: 1px solid $color-E7E7E7;
      }

      &:first-child {
        app-table-row-cell {
          border-top: 1px solid $color-E7E7E7;
        }
      }
    }
  }
}
